<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <v-skeleton-loader ref="skeleton" type="table" v-if="api.isLoading">

        </v-skeleton-loader>
        <v-data-table
        class="elevation-1 mt-5"
        v-if="data!=null && !api.isLoading"
        :items="data"
        :search="searchResult"
        :headers="headers">
            <template v-slot:top>
                <v-toolbar width="auto" height="auto" color="blue lighten-4 py-3">
                    <v-row no-gutters>
                        <v-col class="mt-3 mx-3">
                            <v-row class="mb-3">
                                <v-toolbar-title class="font-weight-bold">
                                    Attachment
                                </v-toolbar-title>
                            </v-row>
                            <v-divider>
                            </v-divider>
                            <v-row class="mt-3">
                                <v-text-field
                                dense
                                outlined
                                clearable
                                v-model="searchResult"
                                label="search">
                                </v-text-field>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-toolbar>
                <AError :api="api"/>
            </template>
            <template v-slot:item.Index="{item}">
                {{ data.indexOf(item)+1 }}
            </template> 
            <template v-slot:item.name="{item}">
                <a v-if="item.companies_id!=33" @click="redirectToCompany(data[data.indexOf(item)].companies_id)" class="text-decoration-none">
                {{ item.name }}
                </a>
                <span v-else>
                    {{ item.name }} (Internal)
                </span>
            </template>
            <template v-slot:item.fname="{item}">
                {{ item.fname }} {{ item.lname }}
            </template>
            <template v-slot:item.created_at="{item}">
                {{ item.created_at.split("T")[0] }} {{ item.created_at.split("T")[1].split('.')[0] }}
            </template>
            <template v-slot:item.delete="{item}">
                <v-btn color="red" plain @click="validateInput(item.attachment_id)">Delete</v-btn>
            </template>
            <template v-slot:item.attachment_name="{item}">
                <a :href="item.attachment_url" target="_blank" download>
                    {{ item.attachment_name }}
                </a>
                <!-- <a @click="redirectDetail(item.form_id)">
                    {{ item.attachment_name }}
                </a> -->
            </template>
        </v-data-table>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import AError from '../../components/common/AError.vue';
import AConfirmation from '../../components/common/AConfirmation.vue';
export default {
    components:{
    AError,
    AConfirmation
},
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        attachment:false,
        searchResult:null,
        isPending:false,
        data:null,
        headers:[
            {
                text:'#',
                value:"Index",
                align:"start",
                sortable:false,
            },
            {
                text:'Attachment',
                value:'attachment_name',
            },
            {
                text:'Type',
                value:'attachment_type',
            },
            {
                text:'Company',
                value:'name',
            },
            {
                text:'PIC',
                value:'fname'
            },
            {
                text:'Date',
                value:'created_at',
            },
    ],
    api:{
        isSucessful:false,
        isLoading :false,
        isError:false,
        error:null,
        url:null,
    }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class=="readAllAttachments"){
                this.data = resp.data;
            }
            if(resp.class=="deleteAttachment"){
                location.reload();
            
            }
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetchReceipt(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/form/attachment";
            return tempApi;
        },
        fetch(){
            let fetchReceiptApi = this.fetchReceipt();
            this.$api.fetch(fetchReceiptApi);
        },
        redirectToCompany(companyId){
            // this.$store.commit("updateCompany",companyId);
            let route = this.$router.resolve({name:'PageCompaniesDetail',params:{'id':companyId}});
            window.open(route.href,'_blank');
        },
        validateInput(attachmentId){
            this.isPending = true;
            this.attachment = attachmentId;
        },
        submit(){
            this.isPending = false;
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "DELETE";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/attachment/"+this.attachment;
            tempApi.params= {
                'userEmail':this.$store.getters.getEmail,
            };  
            this.$api.fetch(tempApi);
        },
        cancelSubmit(){
            this.attachment = null;
            this.isPending = false;
            return 1;
        }
        // redirectDetail(formId){
        //     this.$store.commit("updateForm",formId);
        //     this.$router.push({name:'PageReceiptDetail'});
        // }

    }
}
</script>